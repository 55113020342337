.radcopilot-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.radcopilot-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 95%;
  margin: 0 auto;
  padding: 2rem;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.radcopilot-main.sidebar-open {
  width: calc(95% - 500px);
  margin: 0 auto; /* Keep it centered when sidebar is open */
  transform: translateX(250px); /* Move right by half sidebar width */
}

.radcopilot-title {
  margin-bottom: 20px;
  color: #ffffff;
}

.textarea-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #1e1e1e;
  margin-bottom: 1rem;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
}

.rad-textarea {
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  resize: none;
  background-color: transparent;
  color: transparent;
  caret-color: #ffffff;
  font-family: monospace;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  overflow-y: auto;
}

.text-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  font-size: 16px;
  line-height: 1.5;
  font-family: monospace;
  color: #ffffff;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow-y: auto;
  pointer-events: none;
  box-sizing: border-box;
}

.rad-textarea::-webkit-scrollbar,
.text-content::-webkit-scrollbar {
  width: 8px;
}

.rad-textarea::-webkit-scrollbar-track,
.text-content::-webkit-scrollbar-track {
  background: #1e1e1e;
}

.rad-textarea::-webkit-scrollbar-thumb,
.text-content::-webkit-scrollbar-thumb {
  background: #20B287;
  border-radius: 4px;
}

.rad-textarea::-webkit-scrollbar-thumb:hover,
.text-content::-webkit-scrollbar-thumb:hover {
  background: #23c594;
}

.suggestion-text {
  color: #4CAF50;
  opacity: 0.7;
}

.suggestion-text.deletion {
  color: #ff0000; 
  /* background-color: rgba(255, 0, 0, 0.2); */
  text-decoration: line-through;
  padding: 0 2px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.help-button,
.template-button,
.save-button,
.history-button {
  background-color: #20B287;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.help-button:hover,
.template-button:hover,
.save-button:hover,
.history-button:hover {
  background-color: #23c594;
}

.copy-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #20B287;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.copy-button:hover {
  background-color: #23c594;
}

.copy-button:active {
  background-color: #3e8e41;
}

.copy-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(76, 175, 80, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.suggestion {
  position: absolute;
  font-size: 16px;
  line-height: 1.5;
  color: #4CAF50;
  pointer-events: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  background-color: transparent;
  font-family: monospace;
}

.copy-instruction {
  font-size: 14px;
  color: #888;
  margin-top: 10px;
  font-style: italic;
}

.help-button {
  background-color: #20B287;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.help-modal {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #1e1e1e;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.help-modal h3 {
  margin-top: 0;
}

.help-modal ul {
  list-style-type: none;
  padding: 0;
}

.help-modal li {
  margin-bottom: 10px;
}

.help-modal button {
  background-color: #20B287;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.help-button,

.save-button {
  background-color: #20B287;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 0.5rem;
}

.save-button:hover {
  background-color: #23c594;
}

.chat-trigger-button {
  background-color: #20B287;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-left: 10px;
  transition: background-color 0.3s, transform 0.2s;
}

.chat-trigger-button:hover {
  background-color: #23c594;
  transform: scale(1.1);
}

.chat-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1e1e1e;
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 1000;
  border: 1px solid #333;
}

.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #333;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0 5px;
  color: #ffffff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background: #444;
}

.chat-popup-content {
  position: relative;
}

.chat-popup-content code {
  background-color: #2d2d2d;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9em;
}

.chat-popup-content pre {
  background-color: #2d2d2d;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
}

.chat-popup-content pre code {
  background-color: transparent;
  padding: 0;
}

.chat-popup-content p {
  margin: 0.5em 0;
}

.chat-popup-content h1,
.chat-popup-content h2,
.chat-popup-content h3,
.chat-popup-content h4,
.chat-popup-content h5,
.chat-popup-content h6 {
  color: #4CAF50;
  margin: 1em 0 0.5em 0;
}

.chat-popup-content ul,
.chat-popup-content ol {
  padding-left: 1.5em;
  margin: 0.5em 0;
}

.chat-popup-content a {
  color: #4CAF50;
  text-decoration: none;
}

.chat-popup-content a:hover {
  text-decoration: underline;
}

.report-item {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.embedding-status {
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: 8px;
}

.embedding-status.pending {
  background-color: #ffd700;
  color: #000;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.embedding-status.completed {
  background-color: #4CAF50;
  color: white;
}

.embedding-status.failed {
  background-color: #f44336;
  color: white;
}

.report-item.used-as-context {
  background-color: rgba(76, 175, 80, 0.1);
}

.context-badge {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #20B287;
  color: white;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 3px;
}

.suggestion-indicator {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.suggestion-indicator.loading {
    border: 2px solid #ffffff;
    border-top: 2px solid transparent;
    animation: spin 1s linear infinite;
}

.suggestion-indicator.success {
    background-color: #4CAF50;
}

.suggestion-indicator.empty {
    background-color: #ff0000;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.dictation-active {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #ff4444;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.mic-indicator {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.mic-indicator svg {
  width: 24px;
  height: 24px;
}

.mic-indicator.recording svg {
  fill: #ff4444;
  animation: pulse 1.5s infinite;
}

.textarea-wrapper.clinical-history {
  flex: 1;
  max-height: 25%;
  margin-bottom: 20px;
}

.textarea-wrapper:not(.clinical-history) {
  flex: 3;
}

.textarea-wrapper.clinical-history textarea {
  height: 100%;
  background-color: #1e1e1e;
  color: #ffffff;
}

.tab-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 8px 16px;
  background-color: #1e1e1e;
  color: #ffffff;
  border: 1px solid #444;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button.active {
  background-color: #20B287;
  border-color: #20B287;
}

.tab-button:hover {
  background-color: #23c594;
  border-color: #23c594;
}

.textarea-wrapper.historical-report {
  height: 100%;
}

.textarea-wrapper.historical-report textarea {
  height: 100%;
  background-color: #1e1e1e;
  color: #ffffff;
}

.generate-report-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.generate-report-button {
  background-color: #20B287;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.generate-report-button:hover {
  background-color: #23c594;
}

.generate-report-button:disabled {
  background-color: #333333;
  cursor: not-allowed;
}

.generate-report-button.accept-button {
  background-color: #20B287;
}

.generate-report-button.accept-button:hover {
  background-color: #23c594;
}

.generate-report-button.reject-button {
  background-color: #e74c3c;
}

.generate-report-button.reject-button:hover {
  background-color: #f85c4c;
}

.report-generated-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #20B287;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Loading spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.button-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}

.empty-text-error-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

