/* Chat container */
.chat-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

/* Messages area */
.chat-messages {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Individual messages */
.chat-message {
  padding: 10px 15px;
  border-radius: 8px;
  max-width: 85%;
  line-height: 1.4;
}

.chat-message.user {
  background-color: #2e2e2e;
  margin-left: auto;
  color: #20B287;
}

.chat-message.assistant {
  background-color: #252525;
  margin-right: auto;
  color: #ffffff;
}

/* Input area */
.chat-input {
  padding: 15px;
  border-top: 1px solid #333;
  display: flex;
  gap: 10px;
  background-color: #252525;
}

.chat-input input {
  flex: 1;
  height: 45px;
  padding: 8px 15px;
  border-radius: 6px;
  background-color: #2e2e2e;
  color: #ffffff;
  border: 1px solid #444;
  font-size: 14px;
  line-height: 1.4;
  transition: border-color 0.2s;
  vertical-align: middle;
}

.chat-input input:focus {
  outline: none;
  border-color: #20B287;
}

.chat-input input::placeholder {
  color: #888;
}

.chat-input button {
  background-color: #20B287;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 14px;
}

.chat-input button:hover {
  background-color: #23c594;
}

/* Markdown styling for chat messages */
.chat-message p {
  margin: 0 0 10px 0;
}

.chat-message p:last-child {
  margin-bottom: 0;
}

.chat-message code {
  background-color: #1a1a1a;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9em;
}

.chat-message pre {
  background-color: #1a1a1a;
  padding: 12px;
  border-radius: 6px;
  overflow-x: auto;
  margin: 10px 0;
}

.chat-message pre code {
  background-color: transparent;
  padding: 0;
}

.chat-message ul, 
.chat-message ol {
  margin: 10px 0;
  padding-left: 20px;
  list-style-type: disc;
}

.chat-message ol {
  list-style-type: decimal;
}

.chat-message ul li,
.chat-message ol li {
  margin-bottom: 5px;
}

.chat-message a {
  color: #20B287;
  text-decoration: none;
  vertical-align: middle;
}

.chat-message a:hover {
  text-decoration: underline;
}

/* Scrollbar styling for chat messages */
.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #1e1e1e;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #20B287;
  border-radius: 4px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #23c594;
}

.chat-view.maximized {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background-color: #1e1e1e;
  border-radius: 0;
}

.maximize-button {
  background-color: #20B287;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0 15px;
  cursor: pointer;
  transition: all 0.2s;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-left: 10px;
  position: relative;
}

.maximize-button:hover {
  background-color: #23c594;
  transform: scale(1.05);
}

.maximize-button::before {
  content: '↗';
  transition: transform 0.3s ease;
}

.maximized .maximize-button::before {
  content: '↙';
  transform: rotate(0deg);
}

.maximize-button::after {
  content: 'Maximize';
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.2s;
  white-space: nowrap;
  pointer-events: none;
}

.maximized .maximize-button::after {
  content: 'Minimize';
}

.maximize-button:hover::after {
  opacity: 1;
}

.maximized ~ .mic-indicator,
.sidebar-section.maximized ~ .mic-indicator,
body:has(.maximized) .mic-indicator {
  display: none !important;
}

/* Add these styles for the chat input textarea */
.chat-input-textarea {
  flex: 1;
  min-height: 20px;
  padding: 8px 15px;
  border-radius: 6px;
  background-color: #2e2e2e;
  color: #ffffff;
  border: 1px solid #444;
  font-size: 14px;
  line-height: 1.4;
  max-height: 150px;
  transition: border-color 0.2s;
  resize: none;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.chat-input-textarea:focus {
  outline: none;
  border-color: #20B287;
}

.chat-input-textarea::placeholder {
  color: #888;
  vertical-align: middle;
}