.landing-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  
  .landing-container.fade-out {
    opacity: 0;
  }
  
  .landing-content {
    max-width: 800px;
    text-align: center;
    padding: 2rem;
  }
  
  .landing-title {
    font-size: 4rem;
    color: #20B287;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
    animation: pulse 2s infinite;
    margin-bottom: 2rem;
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  
  .landing-title.fade-out {
    opacity: 0;
  }
  
  .disclaimer-section {
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
  }
  
  .disclaimer-text {
    color: #ffffff;
    margin-bottom: 2rem;
    text-align: left;
    background-color: rgba(30, 30, 30, 0.9);
    padding: 2rem;
    border-radius: 8px;
    border: 1px solid rgba(76, 175, 80, 0.3);
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .disclaimer-text p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .disclaimer-text strong {
    color: #20B287;
  }
  
  .disclaimer-text ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .disclaimer-text li {
    margin-bottom: 0.8rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .disclaimer-text li:before {
    content: "•";
    color: #20B287;
    position: absolute;
    left: 0;
  }
  
  .disclaimer-text ul.nested-list {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    padding-left: 0;
  }
  
  .disclaimer-text ul.nested-list li {
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
  }
  
  .disclaimer-text ul.nested-list li:before {
    content: "○";
    font-size: 0.8rem;
  }
  
  .continue-button {
    background-color: #20B287;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0;
    animation: fadeIn 1s ease-out forwards;
  }
  
  .continue-button:hover {
    background-color: #23c594;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(76, 175, 80, 0.2);
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      text-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
    }
    50% {
      transform: scale(1.05);
      text-shadow: 0 0 20px rgba(76, 175, 80, 0.8);
    }
    100% {
      transform: scale(1);
      text-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .disclaimer-text::-webkit-scrollbar {
    width: 8px;
  }
  
  .disclaimer-text::-webkit-scrollbar-track {
    background: rgba(30, 30, 30, 0.9);
    border-radius: 4px;
  }
  
  .disclaimer-text::-webkit-scrollbar-thumb {
    background: #20B287;
    border-radius: 4px;
  }
  
  .disclaimer-text::-webkit-scrollbar-thumb:hover {
    background: #23c594;
  }