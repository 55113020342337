@import url('https://fonts.googleapis.com/css2?family=Syne:wght@800&display=swap');
@import './RadChat.css';

.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 500px;
    height: 100vh;
    background-color: #1e1e1e;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    color: white;
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .sidebar-toggle {
    position: absolute;
    right: -50px;
    top: 20px;
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .sidebar-toggle img {
    width: 200%;
    height: 200%;
    object-fit: contain;
  }
  
  /* Animation for the hamburger to close icon */
  .sidebar.open .sidebar-toggle span:first-child {
    transform: rotate(45deg);
  }
  
  .sidebar.open .sidebar-toggle span:nth-child(2) {
    opacity: 0;
    transform: translateX(-20px);
  }
  
  .sidebar.open .sidebar-toggle span:last-child {
    transform: rotate(-45deg);
  }
  
  .sidebar-toggle:hover span {
    background-color: #23c594;
  }
  
  .sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .sidebar-title {
    padding: 1rem;
    background-color: #1e1e1e;
    border-bottom: 1px solid #333;
    flex-shrink: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sidebar-title {
    font-family: 'Syne', sans-serif;
    font-weight: 800;
    font-size: 24px;
    margin: 0;
  }

  .sidebar-title .rad {
    color: #434F55;
  }

  .sidebar-title .copilot {
    color: #FFFFFF;
  }
  
  .sidebar-buttons {
    padding: 1rem;
    background-color: #1e1e1e;
    border-bottom: 1px solid #333;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-shrink: 0;
  }
  
  .sidebar-section {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
    color: white;
  }
  
  .sidebar-section h3 {
    margin-bottom: 1rem;
    color: #20B287;
  }
  
  .sidebar-section ul {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }
  
  .sidebar-section li {
    margin-bottom: 0.5rem;
  }
  
  .sidebar-section p {
    margin-bottom: 1rem;
    line-height: 1.4;
  }
  
  .sidebar-section .sidebar-button {
    margin-top: 1rem;
  }

  .sidebar-button.active {
    background-color: #22b387;
    border-left: 3px solid #fff;
  }
  
  /* Scrollbar styling */
  .sidebar-section::-webkit-scrollbar {
    width: 8px;
  }
  
  .sidebar-section::-webkit-scrollbar-track {
    background: #1e1e1e;
  }
  
  .sidebar-section::-webkit-scrollbar-thumb {
    background: #20B287;
    border-radius: 4px;
  }
  
  .sidebar-section::-webkit-scrollbar-thumb:hover {
    background: #23c594;
  }

  .main-content {
    transition: margin-left 0.3s ease-in-out;
    width: 95%;
    padding: 2rem;
    margin: 0 auto;  /* Centers the content */
    position: relative;
  }
  
  .main-content.shifted {
    width: calc(95% - 500px);
    margin: 0 auto;  /* Keep it centered when shifted */
    transform: translateX(250px); /* Half the sidebar width */
  }
  
  .help-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1e1e1e;
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 2000;
  }
  
  .help-modal button {
    background-color: #20B287;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .help-modal button:hover {
    background-color: #23c594;
  }

  .report-history {
    margin-top: 2rem;
    flex: 1;
    overflow-y: auto;
  }
  
  .report-list {
    margin: 0;
    padding: 0;
  }
  
  .report-list li {
    padding: 0.5rem;
    border-bottom: 1px solid #333;
    cursor: pointer;
  }
  
  .report-date {
    font-size: 0.8rem;
    color: #888;
    display: block;
    margin-bottom: 0.25rem;
  }
  
  .report-preview {
    margin: 0;
    font-size: 0.9rem;
    color: #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .settings-toggles {
    padding: 1rem 0;
  }

  .setting-item {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .setting-label {
    color: #fff;
    margin-right: 1rem;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #20B287;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #20B287;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .sidebar-buttons-container {
    background-color: #1e1e1e;
    border-bottom: 1px solid #333;
  }

  .collapse-toggle {
    width: 100%;
    padding: 10px;
    background-color: #2e2e2e;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .collapse-toggle:hover {
    background-color: #3e3e3e;
  }

  .sidebar-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    max-height: 450px;
    overflow: hidden;
    transition: all 0.3s ease-out;
  }

  .sidebar-buttons.collapsed {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    opacity: 0;
  }

  .spinner {
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .report-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .report-history-header h3 {
    margin: 0;
  }

  .report-count {
    font-size: 0.9rem;
    color: #888;
  }

  .report-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .delete-button {
    background: none;
    border: none;
    color: #ff4444;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 4px;
    opacity: 0.7;
    transition: opacity 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  .delete-button:hover {
    opacity: 1;
    background-color: rgba(255, 68, 68, 0.1);
  }

  .report-content {
    cursor: pointer;
    padding: 4px 0;
  }

  .report-content:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .report-item {
    padding: 8px;
    border-bottom: 1px solid #333;
    transition: background-color 0.2s;
  }

  .report-item:hover .delete-button {
    opacity: 1;
  }

  .delete-confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }

  .delete-confirmation-popup {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    color: white;
    text-align: center;
  }

  .delete-confirmation-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }

  .delete-confirmation-buttons button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }

  .delete-confirmation-buttons .confirm-button {
    background-color: #ff4444;
    color: white;
  }

  .delete-confirmation-buttons .confirm-button:hover {
    background-color: #ff6666;
  }

  .delete-confirmation-buttons .cancel-button {
    background-color: #333;
    color: white;
  }

  .delete-confirmation-buttons .cancel-button:hover {
    background-color: #444;
  }

  .profile-section {
    padding: 1rem;
  }

  .profile-section h3 {
    margin-bottom: 1.5rem;
    color: #20B287;
  }

  .profile-form, .password-form {
    margin-bottom: 2rem;
  }

  .message {
    padding: 10px;
    margin-bottom: 1rem;
    border-radius: 4px;
  }

  .message.success {
    background-color: rgba(32, 178, 135, 0.2);
    color: #20B287;
  }

  .message.error {
    background-color: rgba(255, 99, 71, 0.2);
    color: tomato;
  }

  .profile-section .form-group {
    margin-bottom: 1rem;
  }

  .profile-section input {
    width: 100%;
    padding: 8px;
    background-color: #2e2e2e;
    border: 1px solid #444;
    border-radius: 4px;
    color: white;
  }

  .profile-section button {
    width: 100%;
    padding: 8px;
    background-color: #20B287;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }

  .profile-section button:hover {
    background-color: #23c594;
  }

  .profile-info {
    background-color: #2e2e2e;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  .info-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .info-group:last-child {
    margin-bottom: 0;
  }

  .info-group label {
    color: #888;
    font-size: 0.9rem;
  }

  .info-group span {
    color: #fff;
    font-size: 1rem;
  }

  .profile-section h3 {
    margin: 2rem 0 1rem;
    color: #20B287;
  }

  .profile-section h3:first-child {
    margin-top: 0;
  }

  .profile-form, .password-form {
    background-color: #2e2e2e;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 2rem;
  }

  .switch-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #20B287;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .setting-item {
    margin: 15px 0;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
  }

  .copy-to-chat-button {
    background-color: #20B287;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .copy-to-chat-button:hover {
    background-color: #23c594;
  }
  
  .chat-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    gap: 10px;
  }

  .guidelines-section {
    padding: 1rem;
    border-top: 1px solid #333;
    margin-top: 1rem;
  }

  .check-guidelines-btn {
    width: 100%;
    padding: 0.5rem;
    background-color: #20B287;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .check-guidelines-btn:disabled {
    background-color: #666;
    cursor: not-allowed;
  }

  .guidelines-list {
    max-height: 400px;
    overflow-y: auto;
  }

  .guideline-item {
    background: rgba(32, 178, 135, 0.1);
    border-radius: 4px;
    padding: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .guideline-item h4 {
    margin: 0 0 0.5rem 0;
    color: #20B287;
  }

  .guideline-item p {
    margin: 0 0 0.5rem 0;
    font-size: 0.9rem;
  }

  .guideline-item small {
    color: #666;
    font-size: 0.8rem;
  }

  .guidelines-summary {
    background: rgba(32, 178, 135, 0.05);
    border-left: 3px solid #20B287;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0 4px 4px 0;
  }

  .guidelines-summary h4 {
    color: #20B287;
    margin: 0 0 0.5rem 0;
  }

  .guidelines-summary p {
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .settings-submenu {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #333;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .settings-submenu .sidebar-button {
    background-color: #2e2e2e;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .settings-submenu .sidebar-button:hover {
    background-color: #3e3e3e;
  }

  .guidelines-list::-webkit-scrollbar {
    width: 8px;
  }

  .guidelines-list::-webkit-scrollbar-track {
    background: #1e1e1e;
  }

  .guidelines-list::-webkit-scrollbar-thumb {
    background: #20B287;
    border-radius: 4px;
  }

  .guidelines-list::-webkit-scrollbar-thumb:hover {
    background: #23c594;
  }

  .guideline-item p {
    white-space: pre-wrap;
    margin: 0 0 0.5rem 0;
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .guidelines-summary p {
    white-space: pre-wrap;
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.4;
  }

  .most-relevant-section {
    background: rgba(32, 178, 135, 0.1);
    border-left: 3px solid #20B287;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0 4px 4px 0;
  }

  .most-relevant-section h5 {
    color: #20B287;
    margin: 0 0 0.5rem 0;
    font-size: 0.9rem;
  }

  .full-guideline {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .full-guideline h5 {
    color: #666;
    margin: 0 0 0.5rem 0;
    font-size: 0.9rem;
  }

  .guidelines-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
  }

  .guidelines-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(32, 178, 135, 0.1);
    border-radius: 50%;
    border-top-color: #20B287;
    animation: guidelines-spin 1s ease-in-out infinite;
    margin-bottom: 1rem;
  }

  .guidelines-spinner::after {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border: 3px solid transparent;
    border-top-color: rgba(32, 178, 135, 0.3);
    border-radius: 50%;
    animation: guidelines-spin 1.5s linear infinite;
  }

  @keyframes guidelines-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .guidelines-loading-text {
    color: #20B287;
    font-size: 0.9rem;
    margin-top: 1rem;
    animation: guidelines-pulse 1.5s ease-in-out infinite;
  }

  @keyframes guidelines-pulse {
    0%, 100% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .tooltip-text {
    visibility: hidden;
    width: 300px;
    background-color: #333;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 0.9rem;
    line-height: 1.4;
    white-space: normal;
    word-wrap: break-word;
    max-height: 200px;
    overflow-y: auto;
  }

  /* Match scrollbar styling with the sidebar section */
  .tooltip-text::-webkit-scrollbar {
    width: 8px;
  }

  .tooltip-text::-webkit-scrollbar-track {
    background: #1e1e1e;
  }

  .tooltip-text::-webkit-scrollbar-thumb {
    background: #20B287;
    border-radius: 4px;
  }

  .tooltip-text::-webkit-scrollbar-thumb:hover {
    background: #23c594;
  }

  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }

  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  /* Ensure the tooltip doesn't get cut off at the edges */
  .report-content {
    position: relative;
  }

  /* Updated template styling with consistent fonts and proper container sizing */

  .template-form {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #2e2e2e;
    border-radius: 5px;
    border: 1px solid #444;
    width: 100%;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
  }

  .template-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    background-color: #2e2e2e;
    border: 1px solid #444;
    border-radius: 4px;
    color: white;
    font-family: inherit; /* Use the same font as parent */
    font-size: 14px;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
  }

  .template-textarea {
    width: 100%;
    height: 100px;
    padding: 8px;
    margin-bottom: 10px;
    background-color: #2e2e2e;
    border: 1px solid #444;
    border-radius: 4px;
    color: white;
    resize: vertical;
    font-family: inherit; /* Use the same font as parent */
    font-size: 14px;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
  }

  .template-form-buttons {
    display: flex;
    gap: 10px;
    width: 100%;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
  }

  .templates-list {
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
  }

  /* Scrollbar styling for templates list */
  .templates-list::-webkit-scrollbar {
    width: 8px;
  }

  .templates-list::-webkit-scrollbar-track {
    background: #1e1e1e;
  }

  .templates-list::-webkit-scrollbar-thumb {
    background: #20B287;
    border-radius: 4px;
  }

  .templates-list::-webkit-scrollbar-thumb:hover {
    background: #23c594;
  }

  .template-item {
    padding: 10px;
    border-bottom: 1px solid #444;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .template-item:hover {
    background-color: rgba(32, 178, 135, 0.1);
  }

  .template-item.selected {
    background-color: rgba(32, 178, 135, 0.2);
    border-left: 3px solid #20B287;
  }

  .template-preview {
    margin-top: 5px;
    font-size: 12px;
    color: #aaa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .template-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;
  }

  .template-item-header h4 {
    margin: 0;
    font-size: 16px;
    color: #20B287;
    font-family: inherit; /* Use the same font as parent */
  }

  .template-actions {
    display: flex;
    gap: 5px;
  }

  .template-action-button {
    background-color: #3e3e3e;
    border: 1px solid #555;
    border-radius: 3px;
    padding: 3px 8px;
    cursor: pointer;
    font-size: 12px;
    color: white;
    font-family: inherit; /* Use the same font as parent */
  }

  .template-action-button:hover {
    background-color: #4e4e4e;
  }

  .template-action-button.delete {
    color: tomato;
  }

  .template-content {
    margin-top: 5px;
    padding: 8px;
    background-color: #1e1e1e;
    border: 1px solid #444;
    border-radius: 3px;
    max-height: 100px;
    overflow-y: auto;
    color: white;
    width: 100%;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
  }

  /* Scrollbar styling for template content */
  .template-content::-webkit-scrollbar {
    width: 8px;
  }

  .template-content::-webkit-scrollbar-track {
    background: #1e1e1e;
  }

  .template-content::-webkit-scrollbar-thumb {
    background: #20B287;
    border-radius: 4px;
  }

  .template-content::-webkit-scrollbar-thumb:hover {
    background: #23c594;
  }

  /* Ensure Firefox scrollbar styling is also consistent */
  .template-content {
    scrollbar-width: thin;
    scrollbar-color: #20B287 #1e1e1e;
  }

  .template-content pre {
    margin: 0;
    white-space: pre-wrap;
    font-family: inherit; /* Use the same font as parent */
    font-size: 14px;
    color: white;
  }

  .confirmation-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .confirmation-content {
    background-color: #2e2e2e;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    color: white;
    border: 1px solid #444;
    font-family: inherit; /* Use the same font as parent */
  }

  .confirmation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .sidebar-button.small {
    padding: 8px 12px;
    font-size: 12px;
    background-color: #20B287;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-family: inherit; /* Use the same font as parent */
  }

  .sidebar-button.small:hover {
    background-color: #23c594;
  }

  .sidebar-button.small:disabled {
    background-color: #1a8e6c;
    opacity: 0.7;
    cursor: not-allowed;
  }

  .sidebar-button.cancel {
    background-color: #3e3e3e;
    color: white;
  }

  .sidebar-button.cancel:hover {
    background-color: #4e4e4e;
  }

  .no-templates {
    padding: 15px;
    text-align: center;
    color: #aaa;
    font-family: inherit; /* Use the same font as parent */
  }

  .loading {
    padding: 15px;
    text-align: center;
    color: #aaa;
    font-family: inherit; /* Use the same font as parent */
  }

  .sidebar-section-header {
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
  }

  .sidebar-section-header h3 {
    margin: 0;
    color: #20B287;
    font-family: inherit; /* Use the same font as parent */
  }

  /* Add global scrollbar styling to ensure consistency across all scrollable elements */
  .sidebar-section::-webkit-scrollbar {
    width: 8px;
  }

  .sidebar-section::-webkit-scrollbar-track {
    background: #1e1e1e;
  }

  .sidebar-section::-webkit-scrollbar-thumb {
    background: #20B287;
    border-radius: 4px;
  }

  .sidebar-section::-webkit-scrollbar-thumb:hover {
    background: #23c594;
  }

  /* Ensure Firefox scrollbar styling is also consistent */
  .sidebar-section {
    scrollbar-width: thin;
    scrollbar-color: #20B287 #1e1e1e;
  }

  /* Apply the same scrollbar styling to any other scrollable elements */
  .sidebar-content::-webkit-scrollbar {
    width: 8px;
  }

  .sidebar-content::-webkit-scrollbar-track {
    background: #1e1e1e;
  }

  .sidebar-content::-webkit-scrollbar-thumb {
    background: #20B287;
    border-radius: 4px;
  }

  .sidebar-content::-webkit-scrollbar-thumb:hover {
    background: #23c594;
  }

  /* Ensure Firefox scrollbar styling is also consistent */
  .sidebar-content {
    scrollbar-width: thin;
    scrollbar-color: #20B287 #1e1e1e;
  }

  .template-selection-hint {
    font-size: 16px;
    color: #aaa;
    margin-bottom: 10px;
    font-style: italic;
  }

  .add-template-button {
    background-color: #20B287;
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .add-template-button:hover {
    background-color: #23c594;
  }

  .template-form {
    margin-bottom: 15px;
    padding: 10px;
    background-color: rgba(32, 178, 135, 0.1);
    border-radius: 4px;
    animation: slideDown 0.3s ease-out;
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .template-description {
    flex: 1;
  }

  .template-description h3 {
    margin-bottom: 5px;
  }

  .template-usage-hint {
    font-size: 16px;
    color: #aaa;
    margin: 0;
    line-height: 1.4;
  }

  .copy-popup {
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(32, 178, 135, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    z-index: 1000;
  }

  .save-popup {
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(32, 178, 135, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    z-index: 1000;
  }

  .edit-message-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    color: #888;
    font-size: 12px;
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 3px;
  }

  .edit-message-button:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #333;
  }

  .chat-message {
    position: relative;
  }

  .message-edit-icon {
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .message-edit-icon svg {
    fill: #888;
  }

  .chat-message:hover .message-edit-icon {
    opacity: 1;
  }

  .message-edit-icon:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .message-edit-icon:hover svg {
    fill: #333;
  }

  .message-edit-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .message-edit-textarea {
    width: 100%;
    box-sizing: border-box;
    min-height: 60px;
    padding: 8px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: white;
    font-family: inherit;
    font-size: inherit;
    resize: vertical;
  }

  .message-edit-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 8px;
    gap: 8px;
  }

  .message-edit-send,
  .message-edit-cancel {
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }

  .message-edit-send {
    /* Match the green color of the original send button */
    background-color: #20B287; /* Base green color */
    color: white;
    border: none;
    transition: background-color 0.2s ease;
  }

  .message-edit-send:hover {
    /* Slightly different green on hover */
    background-color: #23c594; /* Darker green on hover */
  }

  .message-edit-cancel {
    background-color: transparent;
    border: 1px solid #ddd;
  }

  /* Ensure the chat message takes full width of the sidebar when editing */
  .chat-message.user {
    /* Remove any width or display properties we added */
    /* Let it use the original styling from before our changes */
  }

  /* Ensure the edit container takes full width */
  .message-edit-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Make the textarea expand properly */
  .message-edit-textarea {
    width: 100%;
    box-sizing: border-box;
    min-height: 60px;
    padding: 8px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: white;
    font-family: inherit;
    font-size: inherit;
    resize: vertical;
  }

  /* Ensure the edit actions container is also full width */
  .message-edit-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 8px;
    gap: 8px;
  }

  /* Only apply these styles when a message is being edited */
  .chat-message.user.editing {
    /* Ensure the container allows full width during editing */
    width: 100%;
  }

  .chat-message.user.editing .message-edit-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .chat-message.user.editing .message-edit-textarea {
    width: 100%;
    box-sizing: border-box;
    min-height: 12px;
    padding: 8px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: white;
    font-family: inherit;
    font-size: inherit;
    resize: none;
    overflow-y: hidden;
  }

  .chat-message.user.editing .message-edit-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 8px;
    gap: 8px;
  }

