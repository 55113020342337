body {
  background-color: #121212;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-form {
  background-color: #1e1e1e;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: #ffffff;
}

button {
  width: 100%;
  padding: 0.75rem;
  background-color: #20B287;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #23c594;
}

.error-message {
  color: #ff6b6b;
  margin-top: 1rem;
  text-align: center;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.auth-links {
  margin-top: 1rem;
  text-align: center;
}

.register-link {
  color: #20B287;
  text-decoration: none;
  font-size: 0.9rem;
}

.register-link:hover {
  text-decoration: underline;
}

select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

select option {
  background-color: #333;
  color: #ffffff;
}

.form-group input[type="text"].other-input {
  margin-top: 0.5rem;
}

.forgot-password-link {
  display: block;
  margin-top: 0.5rem;
  color: #20B287;
  text-decoration: none;
  font-size: 0.9rem;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.success-message {
  color: #20B287;
  margin-top: 1rem;
  text-align: center;
}

.back-to-login {
  color: #20B287;
  text-decoration: none;
  font-size: 0.9rem;
}

.back-to-login:hover {
  text-decoration: underline;
}